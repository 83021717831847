import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import BaseSceneCommandModal from './BaseSceneCommandModal';

import SoundEffectView from './visualizations/SoundEffectView';

import DeleteButton from './buttons/DeleteButton';
import ChangeButton from './buttons/ChangeButton';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';

interface Props {
  sceneCommandForm: SoundEffectShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  enableTrimming?: boolean;
  onRequestCloseModal: () => void;
  onForwardToChangeSoundEffects: (
    sceneCommandForm: SoundEffectShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => void;
  onRemoveSoundEffectShowCommand: (
    sceneCommandForm: SoundEffectShowSceneCommandForm,
  ) => void;
  onChangeCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
}

export default class EffectShowSceneCommandModal extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {sceneCommandForm, enableTrimming, onRequestCloseModal} = this.props;
    return (
      <BaseSceneCommandModal
        title={'効果音の編集'}
        onRequestCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.footer}>
            <View style={styles.deleteButtons}>
              <DeleteButton onPress={this.destroySceneCommandForm} />
            </View>
          </View>
        }>
        <View style={styles.body}>
          <View
            style={{
              flex: 1,
              width: '100%',
              flexDirection: 'column',
              alignItems: 'flex-end',
              paddingHorizontal: 32,
              marginVertical: 4,
            }}>
            <ChangeButton title={'効果音変更'} onPress={this.changeEffect} />
          </View>

          <SoundEffectView
            sound={sceneCommandForm.sound}
            startTime={sceneCommandForm.startTime}
            endTime={sceneCommandForm.endTime}
            enableTrimming={enableTrimming}
            onChagenRegion={this.handleChagenRegion}
            onRequestCloseModal={onRequestCloseModal}
          />
        </View>
      </BaseSceneCommandModal>
    );
  }

  private changeEffect = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onForwardToChangeSoundEffects,
    } = this.props;
    onForwardToChangeSoundEffects(
      sceneCommandForm,
      parentSceneCommandForm?.sceneCommandId,
    );
    onRequestCloseModal();
  };

  private destroySceneCommandForm = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onRemoveSoundEffectShowCommand,
      onChangeCommand,
      onRemoveCommand,
    } = this.props;
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.remove(
          parentSceneCommandForm,
          sceneCommandForm,
        );
      if (newParentSceneCommandForm) {
        onChangeCommand(newParentSceneCommandForm);
      } else {
        onRemoveCommand(parentSceneCommandForm);
      }
    } else {
      onRemoveSoundEffectShowCommand(sceneCommandForm);
    }
    onRequestCloseModal();
  };

  private handleChagenRegion = (start?: number, end?: number) => {
    const {sceneCommandForm, onChangeCommand} = this.props;
    const newsceneCommandForm = sceneCommandForm.copy(
      sceneCommandForm.sceneCommandId,
    );
    if (start == undefined && end == undefined) {
      newsceneCommandForm.startTime = null;
      newsceneCommandForm.endTime = null;
    } else {
      newsceneCommandForm.startTime = start === undefined ? null : start;
      newsceneCommandForm.endTime = end === undefined ? null : end;
    }
    onChangeCommand(newsceneCommandForm);
  };
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: '#fafafa',
    alignItems: 'center',
    width: '100%',
  } as ViewStyle,
  button: {
    marginVertical: 5,
  } as ViewStyle,
  deleteButtons: {
    marginTop: 5,
  } as ViewStyle,
  footer: {
    padding: 10,
  } as ViewStyle,
});
