import TapNovelRestApi from '../data_stores/net/TapNovelRestApi';

import EntityMapper from '../entities/mappers/EntityMapper';

import Entity from '../../domain/entities/Entity';
import ResourcesRepository from '../../domain/repositories/ResourcesRepository';

export default class NetResourcesRepositoryAdapter<E extends Entity, D>
  implements ResourcesRepository<E>
{
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private endPoint: string,
    public mapper: EntityMapper<D, E>,
    private suffix?: string,
  ) {}

  public async find(id: number): Promise<E> {
    const path = `${this.endPoint}/${id}?viewer_version=2`;
    const obj = await TapNovelRestApi.get<D>(path);
    return this.mapper.map(obj.body);
  }

  public findAll(): Promise<E[]> {
    return this.findBy({});
  }

  public async findBy(params: {[key: string]: any}): Promise<E[]> {
    const path = this.endPoint;
    const obj = await TapNovelRestApi.get<D[]>(path, params);
    return obj.body.map(storyObj => {
      return this.mapper.map(storyObj);
    });
  }

  public async create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    const path = `${this.endPoint}?viewer_version=2`;
    const obj = await TapNovelRestApi.post<D>(path, params, options);
    return this.mapper.map(obj.body);
  }

  public async update(
    id: number,
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    const path = `${this.endPoint}/${id}${
      this.suffix ? `/${this.suffix}` : ''
    }`;
    const obj = await TapNovelRestApi.patch<D>(path, params, options);
    return this.mapper.map(obj.body);
  }

  public async destroy(id: number): Promise<void> {
    const path = `${this.endPoint}/${id}${
      this.suffix ? `/${this.suffix}` : ''
    }`;
    await TapNovelRestApi.delete(path);
    return;
  }
}
