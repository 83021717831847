import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import Entity from '../../domain/entities/Entity';
import ResourcesRepository from '../../domain/repositories/ResourcesRepository';

export default class NetResourcesRepository<E extends Entity, D>
  implements ResourcesRepository<E>
{
  // eslint-disable-next-line no-useless-constructor
  constructor(private adapter: NetResourcesRepositoryAdapter<E, D>) {}

  public find(id: number): Promise<E> {
    return this.adapter.find(id);
  }

  public findAll(): Promise<E[]> {
    return this.adapter.findAll();
  }

  public findBy(params: {[key: string]: any}): Promise<E[]> {
    return this.adapter.findBy(params);
  }

  public create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    return this.adapter.create(params, options);
  }

  public update(
    id: number,
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    return this.adapter.update(id, params, options);
  }

  public destroy(id: number): Promise<void> {
    return this.adapter.destroy(id);
  }
}
