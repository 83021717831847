import {Dispatch} from 'redux';

import Scene, {Options, EffectOptions} from '../../../domain/entities/Scene';

import NetRawSceneScriptsRepository from '../../../data/repositories/writer/NetRawSceneScriptsRepository';

export interface Params {
  episodeId: number;
  backgroundId: number | null;
  positionedEffectId: number | null;
  options?: Options | null;
  effectOptions?: EffectOptions | null;
  commandsAttributes: {[key: string]: any};
  scriptVersion?: number;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Scene> {
  return async (dispatch: Dispatch<any>): Promise<Scene> => {
    const rawSceneScript = await new NetRawSceneScriptsRepository().create({
      scene: params,
    });
    return rawSceneScript;
  };
}
