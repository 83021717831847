import {PathConfigMap} from '@react-navigation/core';

import {RootStackParamList} from './RootStackParamList';

const LinkingConfig: PathConfigMap<RootStackParamList | {[key: string]: any}> =
  {
    NewStoryNavigation: {
      path: 'stories/new',
      initialRouteName: 'NewStory' as any,
      screens: {
        NewStory: '',
        NewStoryFormCoverImage: 'cover_image',
        NewStoryFormImage: 'image',
        NewStoryFormDetailInfo: 'detail_info',
        StoryFormMainGenre: 'main_genre',
        StoryFormSubGenres: 'sub_genres',
        StoryFormKeywords: 'keywords',
      },
    },
    EditStoryNavigation: {
      path: 'stories',
      screens: {
        EditStory: {
          path: ':storyId/edit',
          parse: {
            storyId: Number,
          },
        },
        EditStoryFormCoverImage: {
          path: ':storyId/edit/cover_image',
          parse: {
            storyId: Number,
          },
        },
        EditStoryFormImage: {
          path: ':storyId/edit/image',
          parse: {
            storyId: Number,
          },
        },
        EditStoryFormDetailInfo: {
          path: ':storyId/edit/detail_info',
          parse: {
            storyId: Number,
          },
        },
        StoryFormMainGenre: {
          path: ':storyId/edit/main_genre',
          parse: {
            storyId: Number,
          },
        },
        StoryFormSubGenres: {
          path: ':storyId/edit/sub_genres',
          parse: {
            storyId: Number,
          },
        },
        StoryFormKeywords: {
          path: ':storyId/edit/keywords',
          parse: {
            storyId: Number,
          },
        },
        StoryFormStoryPublication: {
          path: ':storyId/edit/publication',
          parse: {
            storyId: Number,
          },
        },
        StoryFormStoryPublicationCompletion: {
          path: ':storyId/edit/publication_completion',
          parse: {
            storyId: Number,
          },
        },
      },
    },
    CoverImageNavigation: {
      path: 'cover_images/stories',
      screens: {
        CoverImageFormBackgroundUsageHistories: {
          path: ':storyId/background_usage_histories',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormBaseBackgroundSearchForm: {
          path: ':storyId/base_background_search_form',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormBaseBackgrounds: {
          path: ':storyId/base_backgrounds',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormBackgrounds: {
          path: ':storyId/backgrounds',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormImageCrop: {
          path: ':storyId/image_crop',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormText: {
          path: ':storyId/text',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormTextPreview: {
          path: ':storyId/text_preview',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormCharacterPatterns: {
          path: ':storyId/character_patterns',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormCharacterFormActorSearchForm: {
          path: ':storyId/character_form/actor_search_form',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormCharacterFormActors: {
          path: ':storyId/character_form/actors',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormCharacterFormDefaultCharacterPatterns: {
          path: ':storyId/character_form/default_character_patterns',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormCharacterMakerHome: {
          path: ':storyId/character_maker',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormCharacterMakerActors: {
          path: ':storyId/character_maker/actors',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormCharacterFormCharacterNames: {
          path: ':storyId/character_form/character_names',
          parse: {
            storyId: Number,
          },
        },
        CoverImageFormActorCharacterFaces: {
          path: ':storyId/actor_character_faces',
          parse: {
            storyId: Number,
          },
        },
      },
    },
    CoverImageBaseBackgroundSearchFormNavigation: {
      path: 'cover_images/stories/:storyId/base_background_search_form/filter',
      parse: {
        storyId: Number,
      },
      screens: {
        CoverImageFormBaseBackgroundSearchForm: {
          path: ':storyId',
          parse: {
            storyId: Number,
          },
        },
      },
    },
    CoverImageFormCharacterFormActorSearchFormNavigation: {
      path: 'cover_images/stories/:storyId/character_form/actor_search_form/filter',
      parse: {
        storyId: Number,
      },
      screens: {
        CoverImageFormCharacterFormActorSearchForm: {
          path: ':storyId',
          parse: {
            storyId: Number,
          },
        },
      },
    },
    EpisodeFormEditNavigations: {
      path: 'episodes',
      screens: {
        EditEpisode: {
          path: ':episodeId/edit',
          parse: {
            episodeId: Number,
          },
        },
        EpisodeFormEditEpisodePublication: {
          path: ':episodeId/edit/publication',
          parse: {
            episodeId: Number,
          },
        },
        EpisodeFormEditEpisodePublicationCompletion: {
          path: ':episodeId/edit/publication_completion',
          parse: {
            episodeId: Number,
          },
        },
      },
    },
    CharacterFormNewNavigations: {
      path: 'characters/new/modal',
      initialRouteName: 'CharacterFormNew' as any,
      screens: {
        CharacterFormNew: 'character_form',
        CharacterFormNewActorSearchForm: 'character_form/actor_search_form',
        CharacterFormNewActors: 'character_form/actors',
        CharacterFormNewDefaultCharacterPatterns:
          'character_form/default_character_patterns',
        CharacterFormNewNameLabelColors: 'character_form/name_label_colors',
        CharacterFormNewCharacterMaker: 'character_form/character_maker',
        CharacterFormNewCharacterMakerActors:
          'character_form/character_maker/actors',
      },
    },
    CharacterFormNewActorSearchFormNavigations: {
      path: 'characters/new/modal/character_form/actor_search_form/filter',
      screens: {
        CharacterFormNewActorSearchForm: '',
      },
    },
    CharacterFormEditNavigations: {
      path: 'characters',
      screens: {
        CharacterFormEditCharacterPublication: {
          path: ':characterId/edit/modal/publication',
          parse: {
            characterId: Number,
          },
        },
        CharacterFormEditCharacterPatterns: {
          path: ':characterId/edit/modal/character_patterns',
          parse: {
            characterId: Number,
          },
        },
        CharacterFormEditNameLabelColors: {
          path: ':characterId/edit/modal/name_label_colors',
          parse: {
            characterId: Number,
          },
        },
      },
    },
    Character: {
      path: 'characters:characterId',
      parse: {
        characterId: Number,
      },
    },
    MainStackNavigator: {
      path: '',
      initialRouteName: 'Home' as any,
      screens: {
        Home: '',
        Story: {
          path: 'stories/:storyId',
          parse: {
            storyId: Number,
          },
        },
        Episode: {
          path: 'episodes/:episodeId',
          parse: {
            episodeId: Number,
          },
        },
        Character: {
          path: 'characters/:characterId',
          parse: {
            characterId: Number,
          },
        },
        EditCharacter: {
          path: 'characters/:characterId/edit',
          parse: {
            characterId: Number,
          },
        },
        NewScene: {
          path: 'episodes/:episodeId/scenes/new',
          parse: {
            episodeId: Number,
          },
        },
        EditScene: {
          path: 'scenes/:sceneId/edit',
          parse: {
            sceneId: Number,
          },
        },
        NewCharacter: 'characters/new',
        Menu: 'menu',
        Announcements: 'announcements',
        Announcement: {
          path: 'announcement/:id',
          parse: {
            id: Number,
          },
        },
        Helps: 'helps',
        Help: {
          path: 'helps/:writerHelpCategoryId',
          parse: {
            writerHelpCategoryId: Number,
          },
        },
        Manuals: 'manuals',
        Manual: {
          path: 'manuals/:writerManualCategoryId',
          parse: {
            writerManualCategoryId: Number,
          },
        },
        Extensions: 'extensions',
        AnalysisStories: 'analysis/stories',
        AnalysisStory: {
          path: 'analysis/stories/:storyId',
          parse: {
            storyId: Number,
          },
        },
        AnalysisEpisode: {
          path: 'analysis/episodes/:episodeId',
          parse: {
            episodeId: Number,
          },
        },
        ResponseStories: 'response/stories',
        ResponseStory: {
          path: 'response/stories/:storyId',
          parse: {
            storyId: Number,
          },
        },
        ResponseEpisode: {
          path: 'response/episodes/:episodeId',
          parse: {
            episodeId: Number,
          },
        },
        CommercialVideoExportJobExecutions:
          'commercial_video/export_job_executions',
        CommercialVideoExportJobExecution: {
          path: 'commercial_video/export_job_executions/:exportJobExecutionId',
          parse: {
            exportJobExecutionId: Number,
          },
        },
        CommercialVideoStories: 'commercial_video/stories',
        CommercialVideoStory: {
          path: 'commercial_video/stories/:storyId',
          parse: {
            storyId: Number,
          },
        },
        FullVideoExportJobExecutions: 'full_video/export_job_executions',
        NewFullVideoExportJobExecution: 'full_video/export_job_executions/new',
        NewFullVideoExportJobExecutionFormImage:
          'full_video/export_job_executions/new/image',
        FullVideoExportJobExecution: {
          path: 'full_video/export_job_executions/:exportJobExecutionId',
          parse: {
            exportJobExecutionId: Number,
          },
        },
        FullVideoStories: 'full_video/stories',
        FullVideoStory: {
          path: 'full_video/stories/:storyId',
          parse: {
            storyId: Number,
          },
        },
        ExportStories: 'export/stories',
        ExportStory: {
          path: 'export/stories/:storyId',
          parse: {
            storyId: Number,
          },
        },
        ExportEpisode: {
          path: 'export/episodes/:episodeId',
          parse: {
            episodeId: Number,
          },
        },
        ShopNavigation: {
          path: 'shop',
          screens: {
            ShopActors: 'actors',
            ShopActor: {
              path: 'actors/:id',
              parse: {
                id: Number,
              },
            },
            ShopActorCheckout: {
              path: 'actors/:actorId/checkout',
              parse: {
                actorId: Number,
              },
            },
            ShopActorCheckoutComplete: {
              path: 'actors/:actorId/checkout/complete',
              parse: {
                actorId: Number,
              },
            },
          },
        },
        ShopActorSearchFormNavigation: {
          path: 'shop/actor_search_form/filter',
          screens: {
            ShopActorSearchForm: '',
          },
        },
        ProjectsNavigation: {
          path: 'projects',
          screens: {
            Projects: '',
            ProjectsSearchHome: 'search/home',
            ProjectsSearch: 'search',
            NewProject: 'new',
            Project: {
              path: ':id',
              parse: {
                id: Number,
              },
            },
            ProjectSummary: {
              path: ':id/summary',
              parse: {
                id: Number,
              },
            },
            EditProject: {
              path: ':id/edit',
              parse: {
                id: Number,
              },
            },
            ProjectMembers: {
              path: ':projectId/members',
              parse: {
                projectId: Number,
              },
            },
            ProjectEpisodes: {
              path: ':projectId/episodes',
              parse: {
                projectId: Number,
              },
            },
            ProjectIterations: {
              path: ':projectId/iterations',
              parse: {
                projectId: Number,
              },
            },
            ProjectIteration: {
              path: ':projectId/iterations/:id',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            ProjectManuscript: {
              path: ':projectId/manuscripts/:id',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            NewProjectManuscriptComment: {
              path: ':projectId/manuscripts/:manuscriptId/comments/new',
              parse: {
                projectId: Number,
                manuscriptId: Number,
              },
            },
            NewProjectTopic: {
              path: ':projectId/topics/new',
              parse: {
                projectId: Number,
              },
            },
            EditProjectTopic: {
              path: ':projectId/topics/:id/edit',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            NewProjectTopicComment: {
              path: ':projectId/topics/:topicId/comments/new',
              parse: {
                projectId: Number,
                topicId: Number,
              },
            },
            NewProjectTopicCommentReply: {
              path: ':projectId/topics/:topicId/comments/:commentId/reply/new',
              parse: {
                projectId: Number,
                topicId: Number,
                commentId: Number,
              },
            },
            ProjectTopic: {
              path: ':projectId/topics/:id',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            ProjectUserEpisodes: {
              path: ':projectId/user/episodes',
              parse: {
                projectId: Number,
              },
            },
            ProjectUserEpisode: {
              path: ':projectId/user/episodes/:id',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            NewProjectUserManuscript: {
              path: ':projectId/user/manuscripts/new',
            },
            ProjectUserManuscript: {
              path: ':projectId/user/manuscripts/:id',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            ProjectRequests: {
              path: ':projectId/requests',
              parse: {
                projectId: Number,
              },
            },
            NewProjectEpisodeReleaseRequest: {
              path: ':projectId/episode_release_requests/new',
              parse: {
                projectId: Number,
              },
            },
            ProjectEpisodeReleaseRequest: {
              path: ':projectId/episode_release_requests/:id',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            ProjectCharacters: {
              path: ':projectId/characters',
              parse: {
                projectId: Number,
              },
            },
            EditProjectStoryCoverImage: {
              path: ':projectId/story/cover_image/edit',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            EditProjectStoryImage: {
              path: ':projectId/story/image/edit',
              parse: {
                projectId: Number,
                id: Number,
              },
            },
            EditProjectEpisodeImage: {
              path: ':projectId/episodes/:episodeId/image/edit',
              parse: {
                projectId: Number,
                episodeId: Number,
              },
            },
            ProjectCharacter: {
              path: ':projectId/characters/:characterId',
              parse: {
                projectId: Number,
                characterId: Number,
              },
            },
            EditProjectCharacter: {
              path: ':projectId/characters/:characterId/edit',
              parse: {
                projectId: Number,
                characterId: Number,
              },
            },
            NewProjectCharacter: {
              path: ':projectId/characters/new',
              parse: {
                projectId: Number,
              },
            },
          },
        },
        WritersGateNavigation: {
          path: 'writers_gate',
          screens: {
            WritersGateHome: '',
            WritersGateOffer: {
              path: 'offers/:id',
              parse: {
                id: Number,
              },
            },
            NewWritersGateManuscript: {
              path: 'offers/:offerId/manuscripts/new',
              parse: {
                offerId: Number,
              },
            },
            WritersGateManuscript: {
              path: 'manuscripts/:id',
              parse: {
                id: Number,
              },
            },
            WritersGateManuscripts: {
              path: 'manuscripts',
            },
            WritersGateStories: {
              path: 'stories',
            },
            WritersGateStory: {
              path: 'stories/:id',
              parse: {
                id: Number,
              },
            },
          },
        },
      },
    },
    ShowScene: {
      path: 'scenes/:sceneId',
      parse: {
        sceneId: Number,
      },
    },
    SettingsBankAccount: 'settings/bank_account',
    SettingsUserIdentification: 'settings/user_identification',
    SettingsTransferHistory: 'settings/transfer_history',
    SettingsNavigation: {
      path: 'settings',
      initialRouteName: 'Settings' as any,
      screens: {
        Settings: '',
        SettingsNotifications: 'notifications',
        SettingsBillingPaymentMethods: 'billing/payment_methods',
        NewSettingsBillingPaymentMethod: 'billing/payment_methods/new',
        NewContact: 'contacts/new',
        PoliciesPrivacy: 'policies/privacy',
        PoliciesTerms: 'policies/terms',
        PoliciesSpecifiedCommercialTransactionLaw:
          'policies/specified_commercial_transaction_law',
        PoliciesSettlementLaw: 'policies/settlement_law',
        EditWriterUserRegistrationEmail: 'user/registration_email/edit',
        EditWriterUserRegistrationPassword: 'user/registration_password/edit',
        WriterUserAccount: 'user/account',
        WriterUserVideoShareServiceAccounts:
          'user/video_share_service_accounts',
      },
    },
    FaqNavigation: {
      path: 'faq',
      initialRouteName: 'FaqHome' as any,
      screens: {
        FaqHome: '',
        FaqQuestions: 'questions',
        NewFaqQuestion: 'questions/new',
        FaqQuestion: {
          path: 'questions/:id',
          parse: {
            id: Number,
          },
        },
        NewFaqAnswer: {
          path: 'questions/:questionId/answers/new',
          parse: {
            questionId: Number,
          },
        },
        NewFaqReply: {
          path: 'answers/:answerId/replies/new',
          parse: {
            answerId: Number,
          },
        },
      },
    },
    WriterUserRegistrationNavigation: {
      path: 'users/sign_up',
      screens: {
        NewWriterUserRegistration: '',
        EditWriterUser: 'profile',
        NewWriterTemporaryUsersTermsAcceptance: 'terms_acceptance',
        WriterTemporaryUserRegistrationCompletion:
          'temporary_registration_completion',
      },
    },
    WriterUserSessionNavigation: {
      path: 'users/sign_in',
      screens: {
        NewWriterUserSession: '',
      },
    },
    WriterUserPasswordNavigation: {
      path: 'users/password',
      screens: {
        NewWriterUserPassword: 'new',
        EditWriterUserPassword: 'edit',
        WriterUserPasswordCompletion: 'completion',
      },
    },
    NewWriterUserConfirmation: {
      path: 'users/confirmation/new',
    },

    SceneFormBackgroundShowSceneCommandNewNavigations: {
      path: 'scene_from/background_show_scene_commands/new',
      screens: {
        SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories:
          'background_usage_histories',
        SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm:
          'base_background_search_form',
        SceneFormBackgroundShowSceneCommandNewBaseBackgrounds:
          'base_backgrounds',
        SceneFormBackgroundShowSceneCommandNewBackgrounds: 'backgrounds',
      },
    },
    SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormNavigations: {
      path: 'scene_form/background_show_scene_commands/new/base_background_search_form/filter',
      screens: {
        SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm: '',
      },
    },
    SceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations: {
      path: 'scene_form/background_show_scene_commands/new/background_filters',
      screens: {
        SceneFormBackgroundShowSceneCommandNewBackgroundFilters: '',
      },
    },
    SceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations: {
      path: 'scene_form/background_show_scene_commands/new/background_base_effects',
      screens: {
        SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects: '',
        SceneFormBackgroundShowSceneCommandNewBackgroundEffects:
          ':baseEffectId',
      },
    },
    SceneFormCharacterShowSceneCommandNewNavigations: {
      path: 'scene_from/character_show_scene_commands/new',
      screens: {
        SceneFormCharacterShowSceneCommandNewCharacterPatterns:
          'character_patterns',
        SceneFormCharacterShowSceneCommandNewActorCharacterFaces:
          'actor_character_faces',
        SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm:
          'character_form/actor_search_form',
        SceneFormCharacterShowSceneCommandNewCharacterFormActors:
          'character_form/actors',
        SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns:
          'character_form/default_character_patterns',
        SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames:
          'character_form/character_names',
        SceneFormCharacterShowSceneCommandNewCharacterPositions:
          'character_positions',
        SceneFormCharacterShowSceneCommandNewCharacterMakerHome:
          'character_maker',
        SceneFormCharacterShowSceneCommandNewCharacterMakerActors:
          'character_maker/actors',
      },
    },
    SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchFormNavigations:
      {
        path: 'scene_from/character_show_scene_commands/new/character_form/actor_search_form/filter',
        screens: {
          SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm: '',
        },
      },
    SceneFormSpeechTextShowSceneCommandNewNavigations: {
      path: 'scene_form/speech_text_show_scene_commands/new',
      screens: {
        SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions:
          'speech_text_positions',
        SceneFormSpeechTextShowSceneCommandNewSpeechTexts: 'speech_texts',
      },
    },
    SceneFormDescriptiveTextShowSceneCommandNewNavigations: {
      path: 'scene_form/descriptive_text_show_scene_commands/new',
      screens: {
        SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts:
          'descriptive_texts',
      },
    },
    SceneFormIllustrationShowSceneCommandNewNavigations: {
      path: 'scene_form/illustration_show_scene_commands/new',
      screens: {
        SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories:
          'illustration_usage_histories',
        SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm:
          'illustration_search_form',
        SceneFormIllustrationShowSceneCommandNewIllustrations: 'illustrations',
      },
    },
    SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormNavigations: {
      path: 'scene_form/illustration_show_scene_commands/new/illustration_search_form/filter',
      screens: {
        SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm: '',
      },
    },
    SceneFormIllustrationShowSceneCommandEditNavigations: {
      path: 'scene_form/illustration_show_scene_commands/edit',
      screens: {
        SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories:
          'illustration_usage_histories',
        SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm:
          'illustration_search_form',
        SceneFormIllustrationShowSceneCommandEditIllustrations: 'illustrations',
      },
    },
    SceneFormIllustrationShowSceneCommandEditIllustrationSearchFormNavigations:
      {
        path: 'scene_form/illustration_show_scene_commands/edit/illustration_search_form/filter',
        screens: {
          SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm: '',
        },
      },
    SceneFormFullScreenIllustrationShowSceneCommandNewNavigations: {
      path: 'scene_form/full_screen_illustration_show_scene_commands/new',
      screens: {
        SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration:
          'full_screen_illustrations/new',
        SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrations:
          'full_screen_illustrations',
      },
    },
    SceneFormFullScreenIllustrationShowSceneCommandEditNavigations: {
      path: 'scene_form/full_screen_illustration_show_scene_commands/edit',
      screens: {
        SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration:
          'full_screen_illustrations/new',
        SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations:
          'full_screen_illustrations',
      },
    },

    SceneFormEffectShowSceneCommandNewNavigations: {
      path: 'scene_from/effect_show_scene_commands/new',
      screens: {
        SceneFormEffectShowSceneCommandNewEffectUsageHistories:
          'effect_usage_histories',
        SceneFormEffectShowSceneCommandNewBaseEffectSearchForm:
          'base_effect_search_form',
        SceneFormEffectShowSceneCommandNewBaseEffects: 'base_effects',
        SceneFormEffectShowSceneCommandNewEffects: 'effects',
      },
    },
    SceneFormEffectShowSceneCommandNewBaseEffectSearchFormNavigations: {
      path: 'scene_form/effect_show_scene_commands/new/base_effect_search_form/filter',
      screens: {
        SceneFormEffectShowSceneCommandNewBaseEffectSearchForm: '',
      },
    },
    SceneFormEffectShowSceneCommandNewEffectFiltersNavigations: {
      path: 'scene_form/effect_show_scene_commands/new/effect_filters',
      screens: {
        SceneFormEffectShowSceneCommandNewEffectFilters: '',
      },
    },
    SceneFormEffectShowSceneCommandNewEffectEffectsNavigations: {
      path: 'scene_form/effect_show_scene_commands/new/effect_base_effects',
      screens: {
        SceneFormEffectShowSceneCommandNewEffectBaseEffects: '',
        SceneFormEffectShowSceneCommandNewEffectEffects: ':baseEffectId',
      },
    },

    SceneFormEffectShowSceneCommandEditNavigations: {
      path: 'scene_from/effect_show_scene_commands/edit',
      screens: {
        SceneFormEffectShowSceneCommandEditEffectUsageHistories:
          'effect_usage_histories',
        SceneFormEffectShowSceneCommandEditBaseEffectSearchForm:
          'base_effect_search_form',
        SceneFormEffectShowSceneCommandEditBaseEffects: 'base_effects',
        SceneFormEffectShowSceneCommandEditEffects: 'effects',
      },
    },
    SceneFormEffectShowSceneCommandEditBaseEffectSearchFormNavigations: {
      path: 'scene_form/effect_show_scene_commands/edit/base_effect_search_form/filter',
      screens: {
        SceneFormEffectShowSceneCommandEditBaseEffectSearchForm: '',
      },
    },
    SceneFormEffectShowSceneCommandEditEffectFiltersNavigations: {
      path: 'scene_form/effect_show_scene_commands/edit/effect_filters',
      screens: {
        SceneFormEffectShowSceneCommandEditEffectFilters: '',
      },
    },
    SceneFormEffectShowSceneCommandEditEffectEffectsNavigations: {
      path: 'scene_form/effect_show_scene_commands/edit/effect_base_effects',
      screens: {
        SceneFormEffectShowSceneCommandEditEffectBaseEffects: '',
        SceneFormEffectShowSceneCommandEditEffectEffects: ':baseEffectId',
      },
    },

    SceneFormSoundEffectShowSceneCommandNewNavigations: {
      path: 'scene_from/sound_effect_show_scene_commands/new',
      screens: {
        SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories:
          'sound_usage_histories',
        SceneFormSoundEffectShowSceneCommandNewSoundSearchForm:
          'sound_search_form',
        SceneFormSoundEffectShowSceneCommandNewSounds: 'sounds',
      },
    },
    SceneFormSoundEffectShowSceneCommandNewSoundSearchFormNavigations: {
      path: 'scene_form/sound_effect_show_scene_commands/new/sound_search_form/filter',
      screens: {
        SceneFormSoundEffectShowSceneCommandNewSoundSearchForm: '',
      },
    },
    SceneFormSoundEffectShowSceneCommandNewSoundFiltersNavigations: {
      path: 'scene_form/sound_effect_show_scene_commands/new/sound_filters',
      screens: {
        SceneFormSoundEffectShowSceneCommandNewSoundFilters: '',
      },
    },
    SceneFormSoundEffectShowSceneCommandNewSoundsNavigations: {
      path: 'scene_form/sound_effect_show_scene_commands/new/sounds',
      screens: {
        SceneFormSoundEffectShowSceneCommandNewSounds: '',
      },
    },

    SceneFormSoundEffectShowSceneCommandEditNavigations: {
      path: 'scene_from/sound_effect_show_scene_commands/edit',
      screens: {
        SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories:
          'sound_usage_histories',
        SceneFormSoundEffectShowSceneCommandEditSoundSearchForm:
          'sound_search_form',
        SceneFormSoundEffectShowSceneCommandEditSounds: 'sounds',
      },
    },
    SceneFormSoundEffectShowSceneCommandEditSoundSearchFormNavigations: {
      path: 'scene_form/sound_effect_show_scene_commands/edit/sound_search_form/filter',
      screens: {
        SceneFormSoundEffectShowSceneCommandEditSoundSearchForm: '',
      },
    },
    SceneFormSoundEffectShowSceneCommandEditSoundFiltersNavigations: {
      path: 'scene_form/sound_effect_show_scene_commands/edit/sound_filters',
      screens: {
        SceneFormSoundEffectShowSceneCommandEditSoundFilters: '',
      },
    },
    SceneFormSoundEffectShowSceneCommandEditSoundsNavigations: {
      path: 'scene_form/sound_effect_show_scene_commands/edit/sounds',
      screens: {
        SceneFormSoundEffectShowSceneCommandEditSounds: '',
      },
    },

    SceneFormBackgroundMusicShowSceneCommandNewNavigations: {
      path: 'scene_from/background_music_show_scene_commands/new',
      screens: {
        SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories:
          'sound_usage_histories',
        SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm:
          'sound_search_form',
        SceneFormBackgroundMusicShowSceneCommandNewSounds: 'sounds',
      },
    },
    SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormNavigations: {
      path: 'scene_form/background_music_show_scene_commands/new/sound_search_form/filter',
      screens: {
        SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm: '',
      },
    },
    SceneFormBackgroundMusicShowSceneCommandNewSoundFiltersNavigations: {
      path: 'scene_form/background_music_show_scene_commands/new/sound_filters',
      screens: {
        SceneFormBackgroundMusicShowSceneCommandNewSoundFilters: '',
      },
    },
    SceneFormBackgroundMusicShowSceneCommandNewSoundsNavigations: {
      path: 'scene_form/background_music_show_scene_commands/new/sounds',
      screens: {
        SceneFormBackgroundMusicShowSceneCommandNewSounds: '',
      },
    },

    SceneFormBackgroundMusicShowSceneCommandEditNavigations: {
      path: 'scene_from/background_music_show_scene_commands/edit',
      screens: {
        SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories:
          'sound_usage_histories',
        SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm:
          'sound_search_form',
        SceneFormBackgroundMusicShowSceneCommandEditSounds: 'sounds',
      },
    },
    SceneFormBackgroundMusicShowSceneCommandEditSoundSearchFormNavigations: {
      path: 'scene_form/background_music_show_scene_commands/edit/sound_search_form/filter',
      screens: {
        SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm: '',
      },
    },
    SceneFormBackgroundMusicShowSceneCommandEditSoundFiltersNavigations: {
      path: 'scene_form/background_music_show_scene_commands/edit/sound_filters',
      screens: {
        SceneFormBackgroundMusicShowSceneCommandEditSoundFilters: '',
      },
    },
    SceneFormBackgroundMusicShowSceneCommandEditSoundsNavigations: {
      path: 'scene_form/background_music_show_scene_commands/edit/sounds',
      screens: {
        SceneFormBackgroundMusicShowSceneCommandEditSounds: '',
      },
    },

    AccountNavigation: {
      path: 'account',
      screens: {
        AccountBillingHistory: 'billing/history',
      },
    },

    EditWriterUser: 'user/edit',
    NewWriterUserCancellation: 'user/cancel',
    EpisodeViewer: 'episodes/:episodeId/viewer',
    PreviewSceneViewer: 'scenes/previewer',

    PoliciesPrivacyModal: 'policies/privacy',
    PoliciesTermsModal: 'policies/terms',

    PartnerProgramPoliciesTerms: 'partner_program/policies/terms',
    WritersGatePoliciesTerms: 'writers_gate/policies/terms',

    MonitorNavigation: {
      path: 'monitor',
      screens: {
        MonitorHome: '',
        NewMonitorApplication: 'applications/new',
        MonitorPoliciesTerms: 'policies/terms',
        MonitorPoliciesPrivacy: 'policies/privacy',
      },
    },

    ReservationNavigation: {
      path: 'reservation',
      screens: {
        ReservationHome: '',
        ReservationApplicationCompletion: 'applications/completion',
      },
    },
    CommunityNavigation: {
      path: 'community',
      screens: {
        CommunityHome: '',
      },
    },

    Contest: {
      path: 'contests/:contestId',
      parse: {
        contestId: Number,
      },
    },
    ContestGroup: {
      path: 'contest_groups/:contestGroupId',
      parse: {
        contestGroupId: Number,
      },
    },

    NewEpisodeCommentReply: {
      path: 'episode_comments/:episodeCommentId/new',
      parse: {
        episodeCommentId: Number,
      },
    },
    EditEpisodeCommentReply: {
      path: 'episode_comment_replies/:id',
      parse: {
        id: Number,
      },
    },
    Notifications: {
      path: 'notifications',
    },
    StoryTapCountAchievement: {
      path: 'story_tap_count_achievements/:id',
    },
    StoryGenreRankedAchievement: {
      path: 'story_genre_ranked_achievements/:id',
    },
    UserUpgradeAchievement: {
      path: 'user_upgrade_achievements/:id',
    },
    UserExtension: {
      path: 'user_extensions/:id',
    },
    CommercialVideoExportJobExecution: {
      path: 'commercial_video_export_completion_notifications/:exportJobExecutionId',
      parse: {
        exportJobExecutionId: Number,
      },
    },
    ProNavigation: {
      path: 'pro',
      screens: {
        ProHome: '',
      },
    },
    PartnerProgramNavigation: {
      path: 'partner_program',
      screens: {
        PartnerProgramHome: '',
        PartnerProgramStory: {
          path: 'stories/:id',
          parse: {
            id: Number,
          },
        },
      },
    },
    TransferNavigation: {
      path: 'transfer',
      screens: {
        Transfer: '',
        TransferRequestNew: 'requests/new',
        TransferRequestComplete: 'requests/complete',
      },
    },
  };

export default LinkingConfig;
